<template>
  <div>
    <ca-header heading="Policen" />
    <div class="container-fluid px-4 pt-4">
      <data-table
        :fields="fields"
        service="orders"
        :base-query="baseQuery"
        @row-clicked="(item) => $router.push({name: 'OrderDetail', params: {orderId: item._id}})"
      >
        <template
          slot="hnWarrantynumber"
          slot-scope="{item: order}"
        >
          {{ formatWarrantyNumber(order) }}
        </template>
        <template
          slot="status"
          slot-scope="{value: status, unformatted: statusCode}"
        >
          <span
            class="badge text-white"
            :class="getStatusClass(statusCode)"
          >{{ formatOrderStatus(statusCode) }}</span>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable.vue'
import formatDate from '../../filters/formatDate.js'
import formatOrderStatus from '../../filters/formatOrderStatus.js'
export default {
  name: 'OrderOverviewUser',
  components: {
    DataTable,
    CaHeader
  },
  computed: {
    fields () {
      const fields = [
        {
          label: 'Garantie/Anfrage',
          key: 'hnWarrantynumber'
        },
        {
          label: 'Typ',
          key: 'isKaufschutz',
          formatter: value => value ? 'Kaufschutz' : 'Händler Garantie'
        }
      ]
      if (!this.$route.meta.isOverview) {
        fields.push(
          {
            label: 'Benutzer',
            key: 'user.traderId',
            filter: {
              type: 'SEARCH_IN_POPULATE',
              serviceKey: 'traderId',
              service: 'users',
              targetKey: 'userId'
            }
          },
          {
            label: 'Händler',
            key: 'user.company',
            filter: {
              type: 'SEARCH_IN_POPULATE',
              serviceKey: 'company',
              service: 'users',
              targetKey: 'userId'
            }
          }
        )
      }
      fields.push(
        {
          label: 'UN',
          key: 'product.rate.product.name'
        },
        {
          label: 'Produkt',
          key: 'product.rate.name'
        }
      )
      if (this.$route.meta.isOverview) {
        fields.push(
          {
            label: 'Halter',
            key: 'owner',
            formatter: (owner) => `${owner.firstname} ${owner.lastname}`
          },
          {
            label: 'Kennzeichen',
            key: 'car.licensePlate'
          }
        )
      }
      fields.push(
        {
          label: 'Status',
          key: 'status',
          formatter: statusCode => formatOrderStatus(statusCode),
          filter: {
            type: 'LIST',
            fetchOptions: this.fetchStatusOptions
          }
        },
        {
          label: 'Erstellt am',
          key: 'createdAt',
          formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
          filter: { type: 'DATERANGE' }
        }
      )
      return fields
    },
    baseQuery () {
      const baseQuery = {}
      if (this.$route.meta.isOverview) {
        let userId
        const userIdFromParams = this.$route.params.userId
        if (userIdFromParams === 'me') {
          userId = this.$store.getters['auth/user'].user._id
        } else {
          userId = userIdFromParams
        }

        baseQuery.userId = userId
      } else {
        baseQuery.$client = {
          $showAll: true
        }
      }
      return baseQuery
    }
  },
  methods: {
    async fetchStatusOptions () {
      return [
        { value: 0 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 }
      ]
    },
    formatWarrantyNumber (order) {
      if (!order) return
      if (order.isKaufschutz || !order.product.rate || !order.product.rate.product || !order.product.rate.product.hn) {
        return `${order.contract.warrantyNumber}`
      } else {
        return `${order.product.rate.product.hn}-${order.contract.warrantyNumber}`
      }
    },
    getStatusClass (statusCode) {
      let statusClass = ''
      switch (statusCode) {
      case 0: statusClass = 'badge-primary'; break
      case 1: statusClass = 'badge-success'; break
      case 2:
      case 3:
      case 4: statusClass = 'badge-warning'; break
      }
      return statusClass
    }
  }
}
</script>

<style scoped lang="scss">

</style>
